<template>
  <div style="font-family: Montserrat !important">
    <div v-if="isLoading" style="min-height: 500px">
      <LoaderMain />
    </div>
    <div v-else  style="height: 100%">
      <div v-if="isStep1Valid" class="mx-md-10">
        <BaldenUpdateStep1
          :isLoading="isLoading"
          @loadProducts="getUpdateCollectionData"
          :isLoadingFor="isLoadingFor"
          @gotoPage="gotoPage($event)"
          :quizId="quizId"
        />
      </div>
      <div class="" v-if="isStep2Valid">
        <BaldenUpdateStep2
          @moveTo="moveTo($event)"
          :pageHeight="pageHeight"
          :baldenTitle="ShopTitle"
          :baldenDesc="ShopDescription"
          :apiToken="apiToken"
          :image="getImage"
          :isDesktopView="isDesktopView"
          @updateTextDesc="updateTexTDescription($event)"
          :quizId="quizId"
        />
      </div>
      <div class="mx-md-10" v-if="isStep4Valid || isStep5 ||isStep3Valid">
      <div v-if="isStep3Valid" class="py-0 py-md-3 ">
        <!-- <div v-if="isLoadingProduct" style="min-height: 500px">
          <LoaderMain />
        </div> -->
        <ModalVue
          ref="congratulate"
          style="z-index: 1050; font-family: Montserrat"
          id="congratulate"
          size="md"
          title="<span class='px-3' style='color: #503A3C;

font-family: Montserrat;
font-size: 28px;
font-style: normal;
font-weight: 600;
width: 100%;
    display: block;
    text-align: left;
text-transform: uppercase;'>Congrats! 🥳</span>"
        >
          <div
            class="px-3"
            style="
              color: #503a3c;
              font-family: Montserrat;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              text-align: left;
            "
          >
            Your product selection was saved. Please note that it can take a few
            minutes to load. Next step is to share your shop with customers.
          </div>
          <div class="mt-2 d-flex justify-content-center">
            <ElthBtn
              class="btn elt-btn"
              @click="openShop(live_link)"
              
              style="color: #fff; background-color: #9c4629; border: none"
            >
              <span>{{ translateCustomText(`Promote your shop`) }}</span>
            </ElthBtn>
          </div>
        </ModalVue>
        <BaldanTable
          @payload="(pay) => (payload = pay)"
          @loaded="handleBaldanTableLoaded"
          :email="getEmail"
          :apiToken="apiToken"
          :products="getMergedProducts"
          :options="options"
          @isNotRemoveProducts="isNotRemoveProducts"
          :loading="isLoadingProduct"
          :selectedProducts="getSelectedProducts"
          @update="getSelectedProduct"
          @switch="(e)=>switchBtn=e"
          :quizId="quizId"
          ref="baldanTable"
        ></BaldanTable>
        <div class="text-left">
          <div class="d-flex justify-content-between">
            <ElthBtn variant="boardered" @click="moveToStep1()">
              {{ translateCustomText("Back") }}
            </ElthBtn>
            <ElthBtn
              @click="updateProducts('saveOnly')"
              :isLoading="submitSpinner"
              :disabled="isDisabled"
              v-if="switchBtn=='add more'"
            >
              {{ translateCustomText(`Add selection`) }}
            </ElthBtn>
             <ElthBtn
              @click="removeProducts('saveOnly')"
              :isLoading="submitSpinner"
              :disabled="isDisabled"
              v-else
            >
              {{ translateCustomText(`Remove selection`)}}
            </ElthBtn>
            <button
              style="visibility: hidden"
              class="btn elt-btn d-none d-md-block"
            ></button>
          </div>
        </div>

        <!-- <div class="d-flex mt-md-0 mt-2 justify-content-between">
            <b-button
              pill
              class="filled-button mx-1 my-2"
              "
              >
                            <span class="text-capitalize d-flex justify-content-center align-items-center">  <b-spinner class="mr-1"   v-if="submitSpinner" small label="Small Spinner"></b-spinner></span>

             
            </b-button>
            <b-button
            pill
            class="outline-button mx-1  my-2"
            @click="updateProducts('saveAndExit')"
            :disabled="submitSpinner">
            <span class="text-capitalize d-flex justify-content-center align-items-center">  <b-spinner class="mr-1"   v-if="submitSpinner" small label="Small Spinner"></b-spinner>   {{translateCustomText(`Save & Exit`)}}</span>
            
          </b-button>
          <b-button pill class="outline-button invisible d-none d-md-block mx-1  my-2"  @click="openShop(live_link)">{{ translateCustomText(`Show`) }}</b-button>
           
          </div> -->
      </div>

      <SinglePage
        :imagePlan="imagePlan"
        :RoutePage="RoutePage"
        @moveTo="moveTo($event)"
        v-if="isStep4Valid"
        :apiToken="apiToken"
        :quizId="quizId"
        :isDesktopView="isDesktopView"
        :images="getEditImageQuestion"
        :selected_image="selected_image"
      ></SinglePage>
      <BrandSelection
        :planLimit="planLimit"
        :brandQuestionId="brandQuestionId"
        :selectedBrands="selectedBrands"
        @gotoPage="gotoPage($event)"
        :isLoadingFor="isLoadingFor"
        :RoutePage="RoutePage"
        @moveTo="moveTo($event)"
        v-if="isStep5"
        :apiToken="apiToken"
        :quizId="quizId"
        :isDesktopView="isDesktopView"
        :brands="brandPage"
      ></BrandSelection>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TranslationMixin from "../../../customize/mixin/TranslationMixin";
import cssFunction from "../../../customize/mixin/cssFunction";
import { mapGetters } from "vuex";
import ModalVue from "../../../Dashboard/Layout/Modal.vue";

export default {
  mixins: [TranslationMixin, cssFunction],
  props: [
    "ProductStyles",
    "apiToken",
    "pageHeight",
    "quizId",
    "headingStyle",
    "isDesktopView",
    "RoutePage",
  ],
  data() {
    return {
      notRemoveSelectAllArray:[],
      isNotRemoveSelectAllArray:false,
      switchBtn:'shop',
      imagePlan: "FREE",
      payload: {},
      options: [],
      brandPage: {},
      oldProducts: [],
      selected_image: "",
      imageQuestion: {},
      titleDescription: {
        title: "",
        description: "",
      },
      brandQuestionId: -1,
      selectedBrands: [],
      isLoadingFor: null,
      questionAnswers: [],
      isStep1: true,
      isStep2: false,
      isStep3: false,
      isStep4: false,
      isStep5: false,
      // baldanQuizID:this.getEnvVUE_APP_ITALIAN_QUIZID,
      productType: [],
      productVendors: [],
      selectedVendor: "",
      selectedType: "",
      filter_text: "",
      isLoadingProduct: true,
      collection: {},
      collection_products: [],
      quiz_question_history: [],
      quiz_result_products: [],
      selectedProducts: [],
      isLoading: false,
      selectAllProducts: false,
      submitSpinner: false,
      live_link: "",
      //   isResponse: false,
      //   errorMsgType: null,
      //   collectionLink: null,
      //   errorMsg:null,
      SuccessMsg: "Congratulations! Your collection has been created.",
    };
  },
  components: {
   
    ModalVue,
    ElthBtn: () =>
      import(/* webpackChunkName: "BrandSelection" */ "./ElthButton.vue"),
    BrandSelection: () =>
      import(/* webpackChunkName: "BrandSelection" */ "./Brands.vue"),
    SinglePage: () =>
      import(/* webpackChunkName: "SinglePage" */ "./EditImag.vue"),
    // LoaderMain: () =>
    //   import(
    //     /* webpackChunkName: "LoaderMain" */ "../../../../components/LoaderComps/LoaderMain.vue"
    //   ),
    BaldenUpdateStep1: () =>
      import(/* webpackChunkName: "LoaderComp" */ "./BaldenUpdateStep1.vue"),
    BaldenUpdateStep2: () =>
      import(
        /* webpackChunkName: "BaldenUpdateStep2" */ "./BaldenUpdateStep2.vue"
      ),
    BaldanTable: () =>
      import(/* webpackChunkName: "BaldanTable" */ "./UpdateTable.vue"),
  },

  methods: {
    isNotRemoveProducts(e){
      this.isNotRemoveSelectAllArray=e.isNotRemoveSelectAllArray
      this.notRemoveSelectAllArray=e.notRemoveSelectAllArray
      
    },
    checkConvertIntegar(value) {
      if (Number.isInteger(value)) {
        return value;
      } else {
        return parseInt(value, 10);
      }
    },
    gotoPage(e) {
      if (e.nav == "title") {
        this.getTitleDescription(e);
      } else if (e.nav == "products") {
        this.getProducts(e);
      } else if (e.nav == "image") {
        this.getImageQuestion(e);
      } else if (e.nav == "brands") {
        this.getBrandQuestion(e);
        // this.moveTo(e.step)
      }
    },
    async getTitleDescription(e) {
      this.isLoadingFor = e.nav;
      const payload = {
        email:
          localStorage.getItem("already_record_email") ||
          this.$route.query.email,
        api_token: this.apiToken,
        // collection_id:466432491833
      };
      const response = await this.axiosMethod(
        "post",
        "api/custom/collection/edit/title",
        payload
      );
      if (response.status == 200) {
        this.titleDescription.title = response.data.data.title;
        this.titleDescription.description = response.data.data.description;
        this.moveTo(e.step);
      }
      this.isLoadingFor = null;
      //   this.updateTexTDescription(response.data.data)
      //  console.log()
    },
    async getBrandQuestion(e) {
      this.isLoadingFor = e.nav;
      const payload = {
        email:
          localStorage.getItem("already_record_email") ||
          this.$route.query.email,
        api_token: this.apiToken,
        // collection_id:466432491833
      };
      const response = await this.axiosMethod(
        "post",
        "api/custom/collection/edit/brand",
        payload
      );
      if (response.status == 200 && response.data) {
        this.brandPage =
          response.data.data.question[0].pageDesign.blocksArray[2].options;
        this.selectedBrands = response.data.data.question[0].selectedOption;
        this.brandQuestionId = response.data.data.question[0].id;
        this.planLimit = response.data.data.limit;
        this.moveTo(e.step);
      }
      this.isLoadingFor = null;
      //   this.updateTexTDescription(response.data.data)
      //  console.log()
    },

    findOptionImages(arr){

      const index = arr.findIndex(x=>x.type == 'option')

      return index > -1 ? arr[index].options  : null

    },
    async getImageQuestion(e) {
      this.isLoadingFor = e.nav;
      const payload = {
        email:
          localStorage.getItem("already_record_email") ||
          this.$route.query.email,
        api_token: this.apiToken,
        // collection_id:466432491833
      };
      const response = await this.axiosMethod(
        "post",
        "api/custom/collection/edit/image",
        payload
      );
      if (response.status == 200) {
        let recievedImages = await this.findOptionImages(response.data.data.question.pageDesign.blocksArray)

        if(recievedImages){
          this.imageQuestion = recievedImages
        }
        // this.imageQuestion =
        //   response.data.data.question.pageDesign.blocksArray[2].options;
        this.selected_image = response.data.data.selected_image;
        this.imagePlan = response.data.data.plan;
        //  this.quiz_result_products.description=response.data.data.quiz_result_products;
        this.moveTo(e.step);
      }
      this.isLoadingFor = null;
      //   this.updateTexTDescription(response.data.data)
      //  console.log()
    },
  
     async updateProducts() {
      this.submitSpinner = true;
      let payload = null;
      if (!this.payload.save_all_status) {
         payload = {
        email: this.getEmail,
        api_token: this.apiToken,
        add_products:this.selectedProducts.map(product=>({product_id:product}))
        }
      }
      else
      {

        payload = {
         email: this.getEmail,
         api_token: this.apiToken,
         ...this.payload,
        
       }
      }
       if(this.isNotRemoveSelectAllArray)
        {
       payload.except_ids = this.notRemoveSelectAllArray
       }
      const response = await this.axiosMethod(
        "post",
        "/api/custom/collection/add/products",
        payload
      );
      if (response.status == 200) {
        this.$toasted.show(
          this.translateCustomText(`Your store has been successfully updated.`),
          {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          }
        );

        this.moveToStep1();
            
       
      }
      this.submitSpinner = false;
    },
     async removeProducts() {
      this.submitSpinner = true;
      let payload = null;
      if (!this.payload.save_all_status) {
         payload = {
        email: this.getEmail,
        api_token: this.apiToken,
        products:this.selectedProducts
        }
      }
      else
      {

        payload = {
         email: this.getEmail,
         api_token: this.apiToken,
         ...this.payload,
        
       }
       if(this.isNotRemoveSelectAllArray)
       {
       payload.except_ids = this.notRemoveSelectAllArray
       }
       delete payload.filter.option_ids
      }
      const response = await this.axiosMethod(
        "post",
        "/api/custom/collection/remove/products",
        payload
      );
      if (response.status == 200) {
        this.$toasted.show(
          this.translateCustomText(`Your store has been successfully updated.`),
          {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          }
        );

        this.moveToStep1();
            
       
      }
      this.submitSpinner = false;
    },
    async getProducts(e=null) {
      if(e)
      this.isLoadingFor = e.nav;
   if(e)
        this.moveTo(e.step);
       
      this.isLoadingFor = null;
    },
    async axiosMethod(method, url, payload) {
      return await axios[method](url, payload)
        .then((response) => response)
        .catch((error) => {
          console.log(error);
          this.$toasted.show(
            this.translateCustomText(`An error has occurred`),
            {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            }
          );
          return false;
        });
    },
    handleBaldanTableLoaded() {
      this.isLoadingProduct = false;
    },

    moveTo(step) {
      this.isStep1 = false;
      this.isStep2 = false;
      this.isStep3 = false;
      this.isStep4 = false;
      this.isStep5 = false;
      switch (step) {
        case 1:
          this.isStep1 = true;
          break;
        case 2:
          this.isStep2 = true;
          break;
        case 3:
          this.isStep3 = true;
          break;
        case 4:
          this.isStep4 = true;
          break;
        case 5:
          this.isStep5 = true;
          break;
        default:
          this.isStep1 = true;
          break;
      }
    },
    updateTexTDescription(data) {
      this.collection.title = data.title;
      this.collection.description = data.desc;
    },

    getSelectedProduct(e) {
      this.selectedProducts = e;
    },
    resetFilter() {
      this.selectedVendor = "";
      this.selectedType = "";
      this.filter_text = "";
    },
    openVendor(vendor) {
      if (vendor && !this.allowedQuizID.includes(this.quizId)) {
        let newVendor = vendor.replace(/ /g, "-");
        let url = `https://elth.co.uk/collections/${newVendor}`;
        window.open(url, "_blank");
      }
    },
    translateCustomText(key) {
      return this.translateToItalian(key, this.quizId);
    },
    shareShop(link) {
      if (link) {
        if (!link.startsWith("https")) {
          link = `https://${link}`;
        }
        navigator.clipboard.writeText(link);
        this.$toasted.show(this.translateCustomText(`Link copied!`), {
          position: "bottom-center",
          duration: 2000,
        });
      }
    },
    openShop(link) {
      if (link) {
        if (link.startsWith("https")) {
          window.open(link, "_blank");
        } else {
          let url = `https://${link}`;
          window.open(url, "_blank");
        }
      }
    },
    openExternalLink(link) {
      window.open(link, "_blank");
    },
    getScrollCss() {
      return `max-height:335px;height:100%;    overflow-y: scroll;
  scroll-behavior: smooth;`;
    },
    CloseErrorModal() {
      this.collectionLink = null;
      this.errorMsgType = null;
      this.isResponse = false;
      this.errorMsg = null;
    },
    resetData() {
      this.products = [];
      this.title = null;
      this.description = null;
      this.selectedProducts = [];
    },
    openProduct(product) {
      if (product.detail_link) {
        window.open(product.detail_link, "_blank");
      }
    },

    async updateData(buttonType) {
      this.submitSpinner = true;
      try {
        let data = {
          title: this.collection.title,
          description: this.collection.description,
          email: this.collection.email || this.$route.query.email,
          // products:this.selectedProducts,
          api_token: this.apiToken,
        };

        const response = await axios.post(
          `/api/custom/collection/update/title`,
          data
        );

        if (response.status == 200) {
          this.$toasted.show(
            this.translateCustomText(
              `Your store has been successfully updated.`
            ),
            {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            }
          );

          if (buttonType != "saveOnly") {
            this.moveToStep1();
            this.getUpdateCollectionData();
          }
        } else {
          this.$toasted.show(
            this.translateCustomText(`An error has occurred`),
            {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            }
          );
        }
      } catch (error) {
        if (error) {
          this.$toasted.show(
            this.translateCustomText(`An error has occurred`),
            {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            }
          );
        }
        throw error;
      } finally {
        this.submitSpinner = false;
      }
    },
    restartQuiz() {
      window.location.href = this.$router.history.current.path;
    },

    toggleSingleProduct() {
      this.selectAllProducts = false;
    },
    async selectAllProductsEvent(e) {
      if (e) {
        try {
          this.selectedProducts = await this.products.map((x) =>
            this.checkConvertIntegar(x.external_id)
          );
        } catch (error) {
          if (error) {
            this.$toasted.show(
              this.translateCustomText(`An error has occurred`),
              {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              }
            );
          }
        }
      } else {
        this.selectedProducts = [];
      }
    },

    // getDynamicHeight() {
    //   let myEle = document.querySelector(".UpdateTextArea");

    // },

    async getUpdateCollectionData(movetoProduct) {
      this.isLoading = true;

      try {
        let data = {
          email:
            localStorage.getItem("already_record_email") ||
            this.$route.query.email,
          api_token: this.apiToken,
        };

        const response = await axios.post(
          `/api/custom/collection/detail`,
          data
        );

        if (response.status == 200) {
          this.collection = response.data.data.collection;
          this.collection_products = response.data.data.collection_products;
          this.quiz_question_history = response.data.data.quiz_question_history;
          this.quiz_result_products = response.data.data.quiz_result_products;
          this.questionAnswers = response.data.data.questionAnswers;
          this.selectedProducts = this.collection_products.map((e) =>
            this.checkConvertIntegar(e.external_id)
          );
          if (movetoProduct) this.moveTo(3);
        }
      } catch (error) {
        if (error) {
          this.$toasted.show(
            this.translateCustomText(`An error has occurred`),
            {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            }
          );
        }
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async addProductToCustomCollection(product) {
      this.isLoadingProduct = true;
      try {
        let data = {
          api_token: this.apiToken,
          product_id: product.external_id,
          collection_id: this.collection.collection_id,
        };

        const response = await axios.post(
          `/api/custom/collection/add/product`,
          data
        );

        if (response.status == 200) {
          this.getUpdateCollectionData();
        }
      } catch (error) {
        if (error) {
          this.$toasted.show(
            this.translateCustomText(`An error has occurred`),
            {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            }
          );
        }
        throw error;
      } finally {
        this.isLoadingProduct = false;
      }
    },
    async removeProductFromCustomCollection(product) {
      this.isLoadingProduct = true;
      try {
        let data = {
          api_token: this.apiToken,
          product_id: product.external_id,
          collection_id: this.collection.collection_id,
        };

        const response = await axios.post(
          `/api/custom/collection/remove/product`,
          data
        );

        if (response.status == 200) {
          this.getUpdateCollectionData();
        }
      } catch (error) {
        if (error) {
          this.$toasted.show(
            this.translateCustomText(`An error has occurred`),
            {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            }
          );
        }
        throw error;
      } finally {
        this.isLoadingProduct = false;
      }
    },
    openElthInfo() {
      window.open(`https://elth-store.freshdesk.com/support/home`, "_blank");
    },

    moveToStep1() {
      this.isStep1 = true;
      this.isStep2 = false;
      this.isStep3 = false;
      this.isStep4 = false;
    },
    moveToStep2() {
      this.isStep1 = false;
      this.isStep2 = true;
      this.isStep3 = false;
      this.isStep4 = false;
    },
    moveToStep3() {
      this.isStep1 = false;
      this.isStep2 = false;
      this.isStep3 = true;
      this.isStep4 = false;
    },
    moveToStep4() {
      this.isStep1 = false;
      this.isStep2 = false;
      this.isStep3 = false;
      this.isStep4 = true;
    },
  },
  computed: {
     isDisabled(){
return this.submitSpinner || (this.selectedProducts.length <= 0 && !this.payload.save_all_status) 
    },
    ...mapGetters(["getSelectedPage", "getSavedLeadID", "getEnvConst"]),
    getSelectedProducts(){
        return this.selectedProducts
    },
    getEmail() {
      return (
        localStorage.getItem("already_record_email") || this.$route.query.email
      );
    },
    getEnv() {
      // getEnvConst
      return this.getEnvConst;
    },
    getEditImageQuestion() {
      return this.imageQuestion;
      // if(this.questionAnswers.length)
      //  return this.questionAnswers[this.questionAnswers.length-1]
      //   return this.questionAnswers[0]
    },
    ButtonWidth() {
      return this.allowedQuizID.includes(this.quizId) ? 180 : 150;
    },
    allowedQuizID() {
      return [
        this.getEnv?.VUE_APP_ITALIAN_QUIZID??null,
        this.getEnv?.VUE_APP_ELTH_V3_QUIZID??null,
      ];
    },
    getMergedProducts() {
      const mergedProducts = [
        ...this.collection_products,
        ...this.quiz_result_products,
      ];

      const uniqueProducts = [];

      const seenExternalIds = {};

      for (const product of mergedProducts) {
        const { external_id } = product;
        if (!seenExternalIds[external_id]) {
          uniqueProducts.push(product);
          seenExternalIds[external_id] = true;
        }
      }
      return uniqueProducts;
    },
    showResetFilterBtn() {
      return this.selectedVendor !== "" ||
        this.selectedType !== "" ||
        this.filter_text !== ""
        ? true
        : false;
    },
    ...mapGetters(["getSavedLeadID", "getSelectedOptions"]),
    getCollectionProductTable() {
      return this.collection_products;
    },
    ShopDescription() {
      return this.titleDescription.description;
    },
    ShopTitle() {
      return this.titleDescription.title;
    },
    lastOption() {
      let length = this.getSelectedOptions.questionAnswer.length;
      return this.getSelectedOptions.questionAnswer[length - 1];
    },
    getImage() {
      return this.lastOption ? this.lastOption.selectedOption.image : false;
    },
   
    isSubmitValid() {
      return this.title && this.selectedProducts.length;
    },
    isStep1Valid() {
      return this.isStep1 && !this.isStep2 && !this.isStep3 && !this.isStep4;
    },
    isStep2Valid() {
      return this.isStep2 && !this.isStep1 && !this.isStep3 && !this.isStep4;
    },
    isStep3Valid() {
      return this.isStep3 && !this.isStep2 && !this.isStep1 && !this.isStep4;
    },
    isStep4Valid() {
      return this.isStep4 && !this.isStep2 && !this.isStep1 && !this.isStep3;
    },
  },
  watch: {
    ShopDescription(newVal, oldVal) {
      if (newVal.length > 400) {
        this.titleDescription.description = oldVal;
      }
    },
    ShopTitle(newVal, oldVal) {
      if (newVal.length > 30) {
        this.titleDescription.title = oldVal;
      }
    },
  },
  async mounted() {
  
    // this.getTitleDescription()
    // this.getUpdateCollectionData();
  },
};
</script>

<style scoped>
.input-element {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
}
.CustomTextInputCollection {
  /* color: var(--inputColor) !important; */
  color: #503a3c !important;
  font-size: 18px !important;
}
.productDescription-input {
  width: 100%;
  background-color: transparent;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  outline: none;
  color: #503a3c !important;
  font-size: 18px !important;
  /* color: var(--inputTextColor); */
}
.productDescription-input::placeholder,
.input-element::placeholder {
  /* color: var(--inputPlaceholderColor); */
  color: #503a3c;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
}
.productDescription-input::placeholder {
  font-size: 18px !important;
}
/* .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before */
.SelectAllCheckboxResultPage
  >>> #SelectAllCheckboxResultPage.custom-control-input:checked
  ~ label::before {
  background: #9c4629 !important;
}
.ProductItemCheckbox >>> .custom-control-input:checked ~ label::before {
  background: #9c4629 !important;
}

.ProductInfo {
  text-align: center !important;
}
.ProductTitle:hover {
  text-decoration: underline !important;
}
#ClientProductTable td {
  border-top: 1px solid #503a3c !important;
}

.ClientErrorModalContent {
  border-radius: 20px;
  text-align: left;
}
.Title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 72px;
  color: #4d4950;
}
.TitleSub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #4d4950bb;
}
.btn-tab {
  background: transparent;
  border: 1px solid #cbcddc;
  color: #4d4950;
  font-weight: 600;
  font-size: 14px;
  margin: 0px 4.5px;
}
.btn-tab.active {
  background: #4d1b7e;
  border: 1px solid #4d1b7e;
  color: #ffffff;
}
.w-90 {
  width: 90%;
}

.titleMain {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  text-align: center;

  color: #503a3c;
}

.titleSub {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;

  color: #9c4629;
}

/* .TableDiv{
  
  width:100%;
 
} */

@media screen and (max-width: 750px) {
  .ProductInfo {
    margin-bottom: 0 !important;
  }
  .ResponsiveTable thead {
    display: none;
  }

  .ResponsiveTable,
  .ResponsiveTable tbody,
  .ResponsiveTable tr,
  .ResponsiveTable td {
    display: block;
    width: 100%;
  }
  .ResponsiveTable tr {
    margin-bottom: 15px;
  }
  #ClientProductTable td {
    border-top: none !important;
  }
  .ResponsiveTable td {
    text-align: center;
    /* padding-left: 50%; */
    position: relative;
  }
  .ResponsiveTable td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    text-align: left;
  }
}

.min-width-tr {
  min-width: 100px;
}

.stickyHeader {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 2;
}
.updateshop {
  margin: 10px 10px 10px 20px !important;
}
.filled-button {
  background: #9c4629;
  border-radius: 30px;
  padding: 6px 24px;
  font-weight: 700;
  border: 1px solid #9c4629;
  height: 43px;
  font-size: 14px;
  transition: 0.5s ease all;
  white-space: nowrap;
}
.filled-button:hover {
  color: #9c4629 !important;
  border: 1px solid #9c4629;
  background: transparent;
}
.outline-button:hover {
  background: #9c4629;
  color: #fff;
  border: 1px solid #9c4629;
}
.outline-button {
  color: #9c4629;
  border: 1px solid #9c4629;
  padding: 6px 24px;
  font-weight: 700;
  height: 43px;
  font-size: 14px;
  background: transparent;
  transition: 0.5s ease all;
  white-space: nowrap;
}
.filled-button:active {
  background-color: transparent !important;
  color: #9c4629;
  border: 1px solid #9c4629;
  box-shadow: inset 7px 6px 28px 1px rgba(156, 54, 41, 0.24) !important;
}
.outline-button:active {
  background-color: #9c4629 !important;
  box-shadow: inset 7px 6px 28px 1px rgba(0, 0, 0, 0.24) !important;
  /* Moving button 4px to y-axis */
}
@media only screen and (max-width: 767px) {
  .filled-button,
  .outline-button {
    font-size: 12px;
  }
}
.elt-btn {
  /* background: #503A3C; */
  color: #fff;
  border-radius: 20px;

  min-width: 168px;

  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.elt-btn:focus-visible {
  outline: 1px solid #503a3c;
}
@media screen and (min-width: 991px) {
  .mx-md-10,
  .mx-md-10 {
    /* margin: 0 160px 0 160px !important; */
    max-width:900px;
    margin:0px auto;
    padding: 92px 20px 52px 20px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .mx-md-10,
  .mx-md-10 {
    margin: 0 60px 0 60px !important;
    padding: 38px 20px !important;
  }
}
@media screen and (max-width: 768px) {
  .mx-md-10,
  .mx-md-10 {
    margin: 0px !important;
    padding: 38px 5px !important;
  }
}

</style>
